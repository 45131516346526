<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <p id="supportData" v-bind="attrs" v-on="on"></p>
    </template>

    <v-card style=" padding:20px; " class="text-center">
      <div
        style="margin-top:10px;padding: 0 10px;min-height:150px; width:100%;font-size:14px;"
      >
        <p style="text-align:left">
          Harap mengisi data pendukung dengan sebenar-benarnya
        </p>

        <p style="text-align:left">Data pendukung:</p>

        <div style="display:flex;">
          <p>
            <span style="margin-right:10px;">(1)</span> Referensi penawaran
            vendor atau referensi harga barang dari e-commerce
          </p>
          <v-checkbox
            v-model="reference_from_ecommerce"
            :true-value="1"
            :false-value="0"
            style=" margin:0 0 0 10px; height:25px; padding:0;"
          ></v-checkbox>
        </div>

        <div style="display:flex;">
          <p>
            <span style="margin-right:10px;">(2)</span> Budget yang sudah di
            approve
          </p>
          <v-checkbox
            v-model="budget_is_approved"
            :true-value="1"
            :false-value="0"
            style=" margin:0 0 0 10px; height:25px; padding:0;"
          ></v-checkbox>
        </div>
        <div style="display:flex;">
          <p>
            <span style="margin-right:10px;">(3)</span> Memo atau Berita Acara
            dengan permintaan kondisi khusus
          </p>
          <v-checkbox
            v-model="memo_special_request"
            :true-value="1"
            :false-value="0"
            style=" margin:0 0 0 10px; height:25px; padding:0;"
          ></v-checkbox>
        </div>
        <p style="text-align:left">
          <span style="margin-right:25px;">*</span>Pembelian kategori barang
          <span style="font-weight:bold;">"Investment / Asset"</span> wajib
          diketahui oleh
          <span style="font-weight:bold;">Finance & Accounting</span> dan
          Approval
          <span style="font-weight:bold;">Direktur</span>
        </p>
      </div>
      <div>
        <v-btn
          @click="submit"
          tile
          color="black"
          small
          class="ma-2"
          style="color: white"
        >
          Submit
        </v-btn>

        <v-btn
          small
          tile
          color="black"
          class="ma-2"
          style="color: white"
          @click="cancel"
        >
          Cancel
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      dialog: false,
      memo_special_request: 0,
      budget_is_approved: 0,
      reference_from_ecommerce: 0
    }
  },
  computed: {},
  methods: {
    cancel() {
      this.dialog = false
    },
    submit() {
      const check = {
        memo: this.memo_special_request,
        approvedBudget: this.budget_is_approved,
        reference: this.reference_from_ecommerce
      }
      this.$emit('initSupport', check)
      this.dialog = false
    }
  }
}
</script>
<style></style>
